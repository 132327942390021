import {NewTab} from '../icons';
import './index.less';

const REQUEST_PRICING = 'request pricing';
const PRIVACY_POLICY = 'privacy policy';
const PRIVACY_POLICY_S = 'privacy policy s';
const PRODUCT_TERMS_OF_USE = 'product terms of use';
const PRODUCT_TERMS_OF_USE_LINK = 'product-terms-of-use-link';
const UNSUBSCRIBING = 'unsubscribing';
const CONTACT_US_PRIVACY = 'contact us privacy';

const ExternalLink = ({type}) => {
  if (!type) {
    return;
  }
  if (type === REQUEST_PRICING) {
    return (
      <a
        className="external-link-request-pricing"
        href="https://www.cvent.com/en/ced-price-request"
        alt="request-pricing-link"
        aria-label="request pricing link"
        target="_blank"
      >
        Request pricing
        <NewTab />
      </a>
    );
  }
  if (type === PRIVACY_POLICY) {
    return (
      <a
        className="external-link-privacy-policy"
        href="https://www.cvent.com/en/privacy-policy"
        alt="privacy-policy-link"
        aria-label="privacy policy link"
        target="_blank"
      >
        Privacy Policy
        <NewTab />
      </a>
    );
  }
  if (type === PRIVACY_POLICY_S) {
    return (
      <a
        className="external-link-privacy-policy-s"
        href="https://www.cvent.com/en/privacy-policy"
        alt="privacy-policy-link"
        aria-label="privacy policy link"
        target="_blank"
      >
        Privacy Policy
        <NewTab />
      </a>
    );
  }
  if (type === PRODUCT_TERMS_OF_USE) {
    return (
      <a
        className="external-link-term-of-use"
        href="https://www.cvent.com/en/product-terms-of-use"
        aria-label="product terms of use link"
        alt="product-terms-of-use-link"
        target="_blank"
      >
        Terms of Use
        <NewTab />
      </a>
    );
  }
  if (type === PRODUCT_TERMS_OF_USE_LINK) {
    return (
      <a
        className="external-link-term-of-use-link"
        href="https://www.cvent.com/en/product-terms-of-use"
        aria-label="product terms of use link"
        alt="product-terms-of-use-link"
        target="_blank"
      >
        https://www.cvent.com/en/product-terms-of-use
        <NewTab />
      </a>
    );
  }
  if (type === UNSUBSCRIBING) {
    return (
      <a
        className="external-link-unsubscribing"
        href="https://www.cvent.com/subscriptionmanagement/"
        aria-label="unsubscribe link"
        alt="unsubscribe-link"
        target="_blank"
      >
        unsubscribing
        <NewTab />
      </a>
    );
  }
  if (type === CONTACT_US_PRIVACY) {
    return (
      <a
        className="external-link-contact-us-privacy"
        href="mailto:privacy@cvent.com"
        aria-label="contact us privacy link"
        alt="contact-us-privacy-link"
        target="_blank"
      >
        Contact Us
        <NewTab />
      </a>
    );
  }
};

export {
  ExternalLink,
  REQUEST_PRICING,
  PRIVACY_POLICY,
  PRIVACY_POLICY_S,
  PRODUCT_TERMS_OF_USE,
  PRODUCT_TERMS_OF_USE_LINK,
  UNSUBSCRIBING,
  CONTACT_US_PRIVACY,
};
